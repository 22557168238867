<template>
  <div>
    <div class="header-container flex flex-between" id="header-container">
      <!--左侧-->
      <div class="left flex flex-start">
        <img :src="infoManage.logos.pc_logo_index" alt="" class="logo" @click="clickLogo">
        <div class="navs flex flex-between">
          <a href="/" class="index li">首页</a>
          <a href="/newsList" class="news li">公司新闻</a>
          <a href="/about" class="about li">公司简介</a>
          <div class="product-navs flex flex-start li">
            <a class="title" href="/product" @click.prevent="clickProductNav">思政产品</a>
            <transition name="slide-fade">
            <span class="list flex flex-start">
              <a class="active" @click="clickProductSubNav(0)" href="#experiment">3D/VR虚拟仿真实验教育软件</a>
              <a @click="clickProductSubNav(1)" href="#vr">VR数字展馆</a>
              <a @click="clickProductSubNav(2)" href="#exam">思政理论课在线考试系统</a>
              <a @click="clickProductSubNav(3)" href="#tech">个性化技术支持</a>
            </span>
            </transition>
          </div>

          <a :href="infoManage.experimentUrl" target="_blank" class="experiment-back li" style="cursor: pointer">思政虚拟仿真体验教学中心</a>
          <a href="/#success" class="success li" >成功案例</a>
        </div>
      </div>
      <!--右侧-->
      <div class="right flex flex-start">
        <img src="@/assets/icon/call_black.png" class="black" alt="">
        <img src="@/assets/icon/call_white.png" class="white" alt="">
        <span>客服热线：{{ infoManage.contactObj.phone }}</span>
      </div>
    </div>
    <!--回到顶部按钮-->
    <div class="back-top" style="display: none;cursor: pointer" @click="clickBackTopBtn">
      <img src="http://resouce.cdzyhd.com/88a29a5b-a6d7-41b7-aa92-c55c8520b31c.svg" alt="回到顶部">
    </div>
  </div>
</template>

<script>
import $ from "jquery"
import {ConfigModel} from "@/model/erp/ConfigModel";
import {isMobile} from "@/utils/common";
import {H5_URL} from "@/model/ConfigModel";
import {mapState} from "vuex";

export default {
  name: "headerComponent",
  computed: {
    ...mapState({
      isIndex: state => state.isIndex,
    })
  },
  data() {
    return {
      productNavShow: true,
      productSubNavShowIndex: 0,
      infoManage: {
        logos: {},
        contactObj: {}
      }
    }
  },
  async mounted() {
    // 判断是不是在首页
    if (this.$route.name == "Index") {
      this.isIndex = true
    }

    // 判断是否是手机
    if (isMobile()) {
      window.location.href = H5_URL
    }

    setTimeout(() => {
      this.scrollWatch()
      if (window.location.href.indexOf("/product") > -1) {
        this.scrollWatch_Product()
      }
    }, 350)
    //  如果在产品页，进行滚动条侦测

    let indexManage = await ConfigModel.getIndexConfig();
    this.infoManage = indexManage.infoManage
    // 色彩风格设置
    this.setPageColor()
    // 如果有锚点
    if (this.$route.query.hash) {
      setTimeout(() => {
        location.hash = this.$route.query.hash
      }, 350)
    }

    // 回到顶部悬浮
    $(".back-top img").hover(function () {
      $(this).attr("src", "http://resouce.cdzyhd.com/f88fbf1b-1cd1-4ce4-8277-36dc98fd8752.svg")
    }, function () {
      $(this).attr("src", "http://resouce.cdzyhd.com/88a29a5b-a6d7-41b7-aa92-c55c8520b31c.svg")
    });
  },
  methods: {
    // 点击回到顶部按钮
    clickBackTopBtn() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 页面色彩风格设置
    setPageColor() {
      if (this.infoManage.colorType === "red") {
        document.getElementById("redCSS").disabled = false;
      }
      if (this.infoManage.colorType === "blue") {
        document.getElementById("blueCSS").disabled = false;
      }
    },
    // 屏幕滚动监测-产品页
    scrollWatch_Product() {
      function changeWatch() {
        // 获取鼠标滚轮滚动距离
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (experimentTop <= scrollTop && scrollTop < vrTop && $this.productSubNavShowIndex !== 0) {
          $this.clickProductSubNav(0)
        }
        if (vrTop <= scrollTop && scrollTop < examSystemTop && $this.productSubNavShowIndex !== 1) {
          $this.clickProductSubNav(1)
        }
        if (examSystemTop <= scrollTop && scrollTop < teachTop && $this.productSubNavShowIndex !== 2) {
          $this.clickProductSubNav(2)
        }
        if (scrollTop >= teachTop - 100 && $this.productSubNavShowIndex !== 3) {
          $this.clickProductSubNav(3)
        }
        if (scrollTop > experimentTop) {
          $(".back-top").show()
        } else {
          $(".back-top").hide()
        }
      }

      let $this = this;
      let experimentTop = document.querySelector(".experiment-container").offsetTop
      let vrTop = document.querySelector(".vr-container").offsetTop
      let examSystemTop = document.querySelector(".examSystem-container").offsetTop
      let teachTop = document.querySelector(".tech-container").offsetTop
      changeWatch() // 保证刷新页面也改变
      window.onscroll = () => {
        changeWatch() // 随滚动改变
      }
    },
    // 滚动监测
    scrollWatch() {
      function changeWatch() {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollTop > changeTop) {
          $(".back-top").show()
        } else {
          $(".back-top").hide()
        }
      }

      let changeTop = 0;
      if (window.location.href.indexOf("/product") > -1) {// 产品页
        changeTop = document.querySelector(".experiment-container").offsetTop
      } else {// 首页
        changeTop = document.querySelector(".product-des-container").offsetTop
      }
      changeWatch()
      window.onscroll = () => {
        changeWatch()
      }
    },
    // 点击logo
    clickLogo() {
      window.location.href = "/"
    },
    // 点击产品导航
    clickProductNav() {
      if (this.$route.path !== "/product") {
        window.location.href = "/product"
      }
    },
    // 点击产品子导航
    clickProductSubNav(index) {
      this.productSubNavShowIndex = index
      index = parseInt(index)
      $(".product-navs .list a").removeClass("active")
      $(".product-navs .list a:nth-child(" + (index + 1) + ")").addClass("active")
    }
  }
}
</script>
<style>
.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .2s linear;
}

.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}
</style>
<style scoped lang="less">
.header-container {
  //background-color: rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 1);
  height: 72px;
  padding: 0px 50px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1999;

  .left {
    .logo {
      width: 277px;
      height: 25px;
      display: block;
      margin-right: 68px;
      cursor: pointer;
    }

    .navs {
      // todo 增加导航后 增加宽度
      min-width: 500px;

      > a {
        display: inline-block;
        font-size: 16px;
        color: #000;
        opacity: 0.6;
        text-decoration: none;
        text-align: center;
        height: 72px;
        min-width: 120px;
        line-height: 72px;
        text-align: center;
        padding: 0px 20px;
        overflow: hidden;
      }

      > a.active, > a:hover {
        font-weight: bold;
        opacity: 1 !important;
      }

      .product-navs {
        .title {
          height: 72px;
          line-height: 72px;
          min-width: 105px;
          margin-right: 20px;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          padding: 0px 20px;
          text-decoration: none;
          color: #000;

          &.active, &:hover {
            font-weight: bold;
            opacity: 1;
          }
        }

        .list {
          display: none;
          background-color: #f8f8f8;
          height: 72px;
          transform: skewX(-25deg);
          padding-left: 40px;
          width: 780px;
          transition: all 0.3s linear;

          a {
            font-size: 14px;
            font-weight: bold;
            line-height: 19px;
            color: #9D9D9D;
            display: inline-block;
            margin-right: 60px;
            transform: skewX(25deg);
            text-decoration: none;

            &:last-child {
              margin-right: 0;
            }

            &:hover, &.active {

            }
          }
        }
      }
    }
  }

  .right {
    min-width: 220px;

    img {
      margin-right: 10px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: #fff;
      opacity: 0.6;
    }
  }
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
