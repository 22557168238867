// api地址-erp后端
// const API_URL_ERP = "http://192.168.0.60:8900/"; // 本地
// const API_URL_ERP = "http://116.63.187.43:8900/"; // 测试
const API_URL_ERP = "https://api.cdzyhd.com/system/erp/"; // 正式
// const API_URL_ERP = "http://localhost:8900/"; // 本地测试

// 配置名称
const CONFIG_NAME = "config_officialWeb" // 正式
// const CONFIG_NAME = "config_officialWeb_test" //测试

// H5版本地址
const H5_URL = "http://h5.cdzyhd.com"
// const H5_URL = "http://h5-zyhd.xhyjbj.com"

// 配置名称-活动页
const CONFIG_NAME_ACTIVITY = "config_activity" // 正式
// const CONFIG_NAME_ACTIVITY = "config_activity_test" // 测试

// 配置名称-VersionManager
const CONFIG_NAME_VERSION_MANAGER = "config_versionManager" // 正式
// api地址-versionManager
const API_URL_VERSION_MANAGER = "http://api.cdzyhd.com/system/versionManager/"

export {API_URL_ERP, CONFIG_NAME, H5_URL, CONFIG_NAME_ACTIVITY, CONFIG_NAME_VERSION_MANAGER, API_URL_VERSION_MANAGER}
